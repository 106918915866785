import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../../../components/chart';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    name: 'Team A',
    type: 'column',
    data: [  0, 1, 1, 0, 0, 1, 1, 0, 1, 2, 5, 1, 1, 1, 2, 6, 5, 1, 0, 0, 0, 2, 6, 2, 4, 1, 2, 5, 0, 3, 2, 3, 1, 1, 1, 1, 4, 1, 0, 0, 3, 7, 7, 0, 3, 1, 3, 13, 1, 0, 0, 2, 2, 2, 1, 1, 0, 0, 1, 8, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: 'Team B',
    type: 'area',
    data: [  0, 1, 1, 0, 0, 1, 1, 0, 1, 2, 5, 1, 1, 1, 2, 6, 5, 1, 0, 0, 0, 2, 6, 2, 4, 1, 2, 5, 0, 3, 2, 3, 1, 1, 1, 1, 4, 1, 0, 0, 3, 7, 7, 0, 3, 1, 3, 13, 1, 0, 0, 2, 2, 2, 1, 1, 0, 0, 1, 8, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  
  },
  {
    name: 'Team C',
    type: 'line',
    data: [ 0, 1, 1, 0, 0, 1, 1, 0, 1, 2, 5, 1, 1, 1, 2, 6, 5, 1, 0, 0, 0, 2, 6, 2, 4, 1, 2, 5, 0, 3, 2, 3, 1, 1, 1, 1, 4, 1, 0, 0, 3, 7, 7, 0, 3, 1, 3, 13, 1, 0, 0, 2, 2, 2, 1, 1, 0, 0, 1, 8, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  
  },
];

function convertDateFormat(dateString) {
  const parts = dateString.split('/');
  if (parts.length === 3) {
    const [dd, mm, yyyy] = parts;
    // Reformat the date as mm/dd/yyyy
    return `${mm}/${dd}/${yyyy}`;
  }
  // Return the original string if it doesn't match the expected format
  return dateString;
}


export default function AnalyticsWebsiteVisits() {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '14%' } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: [
'09/07/2023',
'10/07/2023',
'11/07/2023',
'12/07/2023',
'13/07/2023',
'14/07/2023',
'15/07/2023',
'16/07/2023',
'17/07/2023',
'18/07/2023',
'19/07/2023',
'20/07/2023',
'21/07/2023',
'22/07/2023',
'23/07/2023',
'24/07/2023',
'25/07/2023',
'26/07/2023',
'27/07/2023',
'28/07/2023',
'29/07/2023',
'30/07/2023',
'31/07/2023',
'01/08/2023',
'02/08/2023',
'03/08/2023',
'04/08/2023',
'05/08/2023',
'06/08/2023',
'07/08/2023',
'08/08/2023',
'09/08/2023',
'10/08/2023',
'11/08/2023',
'12/08/2023',
'13/08/2023',
'14/08/2023',
'15/08/2023',
'16/08/2023',
'17/08/2023',
'18/08/2023',
'19/08/2023',
'20/08/2023',
'21/08/2023',
'22/08/2023',
'23/08/2023',
'24/08/2023',
'25/08/2023',
'26/08/2023',
'27/08/2023',
'28/08/2023',
'29/08/2023',
'30/08/2023',
'31/08/2023',
'01/09/2023',
'02/09/2023',
'03/09/2023',
'04/09/2023',
'05/09/2023',
'06/09/2023',
'07/09/2023',
'08/09/2023',
'09/09/2023',
'10/09/2023',
'11/09/2023',
'12/09/2023',
'13/09/2023',
'14/09/2023',
'15/09/2023',
'16/09/2023',
'17/09/2023',
'18/09/2023',
'19/09/2023',
'20/09/2023',
'21/09/2023',
'22/09/2023',
'23/09/2023',
'24/09/2023',
'25/09/2023',
'26/09/2023',
'27/09/2023',
'28/09/2023',
'29/09/2023',
'30/09/2023'
    ].map(convertDateFormat),
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader title="Bookings" subheader="(+43%) than last year" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
